.btn {
    transition: all 0.2s ease-in-out;
    border-radius: 1rem;
}

.btn:hover {
    transform: scale(1.03);
}

.btn-primary {
    background-color: #3C79F5 !important;
    border: none;
    border-radius: 1rem !important;
}

.btn-primary:hover {
    background-color: #3365cc !important;
    box-shadow: 0px 0px 30px rgba(51, 101, 204, 0.5);   
}

.btn-outline-primary {
    color: #3365cc;
}

.btn-outline-primary:hover {
    background-color: #3365cc;
    color: white;
    box-shadow: 0px 0px 30px rgba(51, 101, 204, 0.5);  
}

.btn-outline-danger:hover {
    box-shadow: 0px 0px 30px rgba(220, 53, 69, 0.5);  
}

input,
select {
    color: #FFF !important;
    background-color: #2D3748 !important;
    /* border-radius: 1rem !important; */
    border: none !important;
    /* border: 1px solid !important; */
    /* border-color: #718096 !important; */
}

.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #718096;
    opacity: 1;
    /* Firefox */
}

.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #718096;
}

.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #718096ed;
}

select option:checked {
    background-color: #3C79F5 !important;
    color: white !important;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Remove default arrow */
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%233C79F5' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E") !important;
    /* Add custom arrow */
}

hr.solid {
    border-top: 2px solid #3C79F5;
    border-radius: 100px;
}

a {
    color: white;
    text-decoration-color: #3C79F5;
}

.card {
    border-radius: 1rem !important;
}

/* Accordion Start */

.list-group-item,
.accordion-item {
    background: transparent !important;
}

.accordion-button {
    background-color: var(--third-bg-color);
    color: #fff;
    margin-bottom: 1rem;
    border-radius: 1rem !important;
}

.accordion-body {
    color: #fff;
}

.accordion-button:not(.collapsed) {
    background-color: var(--third-bg-color);
    color: #3C79F5;
}

.accordion-item {
    border: none;
}

.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/* Accordion End */
