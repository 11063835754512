/*

PALETTE: https://colorhunt.co/palette/6c00ff3c79f52dcddff2deba
PURPLE: 6C00FF
BLUE: 3C79F5

*/

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');

body {
  font-family: 'Raleway', sans-serif;
  background-color: #171923;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrapper {
  min-height: calc(100vh - 250px);
}

.alert-primary {
  background: linear-gradient(90deg, rgba(60, 121, 245, 0.2) 49%, rgba(108, 0, 255, 0.2) 100%) !important;
  /* border-color: #3C79F5; */
  color: #FFF;
}

.btn-link,
.btn-link:hover {
  color: white;
  text-decoration-color: var(--first-bg-color);
}

.special-text {
  /* text-shadow: 0 0 7px var(--first-bg-color); */
  background: url("https://magicgen.xyz/img/specialtext.gif");
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.special-button {
  background: url("https://magicgen.xyz/img/specialtext.gif");
  background-size: cover;
  color: white;
  transition: background-color 0.3s ease;
  background-clip: padding-box;
}