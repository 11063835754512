:root {
    --first-bg-color: #3C79F5;
    --second-bg-color: #171923;
    --third-bg-color: #2D3748;

    --main-border-color: #718096;
}

.navbar-dark {
    background-color: #171923;
}

.navbar-text {
    color: #FFF;
}

.active {
    color: #3C79F5 !important;
}

.iwt {
    display: block;
}

.bg-black {
    background-color: #FFF
}

.navbar-brand {
    padding: 6px 20px;
    font-size: 16px;
    background: transparent !important;
    border: none !important;
}

.navbar-nav {
    width: 100%
}

.nav-item {
    padding: 0px 8px;
    margin: 0px 3px;
    /*text-align: center;*/
    /*border-radius: 5px;*/
    border-radius: 1rem;
}

.nav-item.active {
    background-color: #2D3748;
}

.nav-item:hover {
    background-color: #2D3748;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:active,
.navbar-dark .navbar-nav .nav-link:focus {
    color: #FFF;
    background: transparent !important;
    border: none !important;
}

.icon {
    /* padding-right: 10px; */
    position: relative;
    top: 1.5px;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
    border: none;
}

/* Animated Dropdown */

@media (min-width: 768px) {
    .animate {
        animation-duration: 0.3s;
        -webkit-animation-duration: 0.3s;
        animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0rem);
        opacity: 1;
    }

    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -webkit-opacity: 1;
    }

    0% {
        -webkit-transform: translateY(1rem);
        -webkit-opacity: 0;
    }
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
}

.navbar-nav>li>.dropdown-menu {
    z-index: 1021;
    background-color: #2D3748;
    /*border: none;*/
    /* border-color: #718096; */
    border-radius: 1rem;
}

.navbar-nav>li>.dropdown-menu a {
    color: #FFF;
}

.auth>a:hover {
    background-color: #1A202C;
    color: #3C79F5;
}

/*
.navbar-nav>li>.dropdown-menu a:hover {
    background-color: #1A202C;
    color: #3C79F5;
}
*/

.navbar-nav>li>.dropdown-menu .active {
    background-color: #1A202C;
    color: #3C79F5;
}

.flag {
    position: relative;
    bottom: 1px;
}

.text-wrap {
    width: 100%;
}

.bg-info {
    background: linear-gradient(90deg, rgba(60, 121, 245, 0.2) 49%, rgba(108, 0, 255, 0.2) 100%) !important;
}

.products>div>div>div:hover {
    background-color: var(--second-bg-color);
    border-radius: 1rem;
}

/*

NavigationBar Ends

*/
/*  */

footer {
    /*margin-top: auto;*/
    position: relative;
    left: 0;
    bottom: 0;
}

