.card {
    transition: all 0.3s ease-in-out;
    background-color: #2D3748;
    /* border-color: #718096; */
}

.card:hover {
    transform: scale(1.03);
}

.card-img-top {
    position: relative;
    display: block;
}

.card-img-elements {
    left: 2%;
    top: 1%;
    position: absolute;

}

.main-color {
    background-color: #3C79F5;
}

